import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import SEO from '../components/seo'

import Layout from '../components/layout'

import i18n from '../i18n/i18n'
import { getTitle } from '../util/title-helper'

import './styles/basic.css'

const Basic = ({ data, pageContext }) => {
  const type = pageContext.type

  const pageData = data[type].data

  const lang = pageContext.lang.split('-')[0]

  let additional = null

  if (pageData.pictures) {
    additional = pageData.pictures.map(picture => (
      <Image
        className="basic__picture"
        fluid={picture.picture.localFile.childImageSharp.fluid}
        key={picture.picture.localFile.id}
      />
    ))
  } else if (pageData.location) {
    additional = (
      <iframe
        title="Map view"
        width="550"
        height="400"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCXASYVL6HYTJn9-Yv_tqV4SEG3yKcrfAk&q=${pageData.location.latitude},${pageData.location.longitude}+(Niemi-Kapeen+tila)&zoom=12&maptype=satellite`}
      />
    )
  }

  return (
    <Layout page={type}>
      <Helmet>
        <title>{getTitle(lang, i18n[lang][type].title)}</title>
      </Helmet>
      <SEO title={getTitle(lang, i18n[lang][type].title)} lang={lang} />
      <div className="basic">
        <div
          className="basic__col basic__body"
          dangerouslySetInnerHTML={{ __html: pageData.body.html }}
        />
        {additional ? (
          <div className="basic__col basic__additional">{additional}</div>
        ) : null}
      </div>
    </Layout>
  )
}

export default Basic

export const pageQuery = graphql`
  query PageBySlug($id: String!, $lang: String!) {
    events: prismicEvents(id: { eq: $id }, lang: { eq: $lang }) {
      id
      data {
        body {
          html
        }
        pictures {
          picture {
            url
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    price: prismicPrice(id: { eq: $id }, lang: { eq: $lang }) {
      id
      data {
        body {
          html
        }
        pictures {
          picture {
            url
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    gift: prismicGift(id: { eq: $id }, lang: { eq: $lang }) {
      id
      data {
        body {
          html
        }
        pictures {
          picture {
            url
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    catering: prismicCatering(id: { eq: $id }, lang: { eq: $lang }) {
      id
      data {
        body {
          html
        }
        pictures {
          picture {
            url
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    contact: prismicContact(id: { eq: $id }, lang: { eq: $lang }) {
      id
      data {
        body {
          html
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`
